import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { ToastTypes, withToast } from "../../contexts/toastr.context";
import { useAppService } from "../../hooks/use-app-service";
import { createMeetingLinkWithToken } from "../../_pages";
import {
  eventTimeV2,
  formatEventDate,
  formatEventDetailsDate,
  formatMonthAndDay,
} from "../../_pages/event-time-v2.page";
import { EventService } from "../../_service/event.service";
import I18 from "../atoms/i18";
import { VirtualEventIcon } from "../atoms/icons/virtual-event-icon";
import { Button } from "../button.component";
import UIcon from "../uicon-component";

function SidebarEventDetailsCardComponent({
  community,
  joinScheduledPersonalMeeting,
  joinScheduledMeeting,
  event,
  addToast,
  onAttendeeClick = () => {},
  getEventDetails = () => {},
  user,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isHost, setIsHost] = useState(false);
  const { monthAbbreviation, dayOfMonth } = formatMonthAndDay(event.startTime);
  const rsvpNotAllowed =
    (event.myRSVPStatus !== "going" &&
      event.myRSVPStatus !== "maybe" &&
      event.maximumRegistrations - event.participantCount === 0) ||
    isHost;

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const { analyticsService } = useAppService();

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /** Using REF as window.document.body is considering the dropdown also
       * if clicked on outside of Dropdown Div
       */
      function closeMenuListener(event) {
        if (ref.current && !ref.current.contains(event.target)) {
        }
      }
      // Bind the event listener
      document.addEventListener("click", closeMenuListener);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", closeMenuListener);
      };
    }, [ref]);
  }

  const onSubmit = (attending) => {
    setIsLoading(true);
    analyticsService.track("rsvp-selected", {
      rsvpType: attending,
    });
    EventService.updateRSVP(user, event.community, event.id, attending)
      .then((response) => {
        getEventDetails();
        addToast(
          attending === "going"
            ? "Successfully registered to event!"
            : "Successfully unregistered from event!",
          "",
          ToastTypes.success
        );
      })
      .catch((error) => {
        console.log(error);
        addToast("Error in accepting invitation", "", ToastTypes.danger);
      });
  };

  // add link to google calendar
  const generateGoogleCalendarUrl = () => {
    const start = new Date(event.startTime).toISOString().replace(/[:-]/g, "");
    const end = new Date(event.endTime).toISOString().replace(/[:-]/g, "");
    const title = encodeURIComponent(event.title);
    let privateLink = "";
    if (event.liveMeeting) {
      privateLink = createMeetingLinkWithToken(
        event.liveMeeting.meetingId,
        user.token
      );
    }
    const meetingLink = event.webURL || privateLink;

    const desc = encodeURIComponent(
      `${event.description}\n\n${
        meetingLink
          ? `Join here: <a href="${meetingLink}">${meetingLink}</a>`
          : ""
      }`
    );
    const timezone = community.timezone;
    const location = event.location;

    const url = `https://www.google.com/calendar/render?action=TEMPLATE&dates=${start}/${end}&text=${title}&details=${desc}&ctz=${timezone}&location=${location}`;
    window.open(url);
  };
  return (
    <div className="w-full flex-col justify-center md:mx-auto md:max-w-[670px] lg:min-w-[364px] xl:max-w-[364px]">
      <div className="rounded-xl bg-card p-5">
        <div className="border-b border-border pb-4 font-bold text-secondary-foreground">
          <I18>TIME & LOCATION</I18>
        </div>
        <div className="mt-5 flex space-x-2">
          <div className="flex-col">
            <div className="text-xxs rounded-t-md bg-primary px-2.5 py-0.5 text-primary-foreground">
              {monthAbbreviation}
            </div>
            <div className="text-xxs items-center rounded-b-md border border-border px-2.5 py-1.5 text-secondary-foreground drop-shadow-md">
              <span className="ml-0.5">{dayOfMonth}</span>
            </div>
          </div>
          <div className="flex-col text-secondary-foreground">
            <div className="text-sm font-semibold">
              {formatEventDate(event.startTime)}
            </div>
            <div className="flex items-center text-sm font-semibold">
              <span>{eventTimeV2(event.startTime)}-</span>
              <span>{eventTimeV2(event.endTime)}</span>
            </div>
          </div>
        </div>
        <div className="mt-7 flex space-x-2">
          <div className="flex items-center rounded-md border border-border p-2">
            <VirtualEventIcon className="text-secondary-foreground" />
          </div>
          <div className="flex-col text-secondary-foreground">
            <div className="text-sm font-semibold">
              {event?.webURL ? "Online Event" : "Location"}
            </div>
            {event?.webURL && event?.liveMeeting === null ? (
              <Button
                disabled={new Date(event.startTime) > new Date()}
                onClick={joinScheduledPersonalMeeting}
                className="w-full rounded-lg border border-border p-1.5 text-center text-xs"
                label={
                  new Date(event.startTime) > new Date()
                    ? `Join in: ${formatEventDetailsDate(event.startTime)}`
                    : "Join Event"
                }
              />
            ) : <div className="flex items-center text-sm font-semibold">
            <span>-</span>
          </div> }
          </div>
        </div>
      </div>

      {event.myRSVPStatus === "going" ? (
        <div className="my-2.5 rounded-xl bg-card p-5 text-secondary-foreground">
          <div className="border-b border-border pb-4 font-bold">
            <I18>REGISTER</I18>
          </div>
          <div className="mt-5">
            <div className="flex-col text-sm font-semibold">
              <div>YOU'RE IN</div>
              <div className="mt-2.5">
                A confirmation email has been sent to {user.email}
              </div>
              <div className="mt-5 w-full rounded-lg border border-border p-2.5 text-center text-xs">
                Event will start in: {formatEventDetailsDate(event.startTime)}
              </div>
              <div className="flex items-center justify-center gap-2 text-xs">
                <div className="mt-5 w-full rounded-lg border border-primary px-2.5 py-1.5 text-primary">
                  <button
                    className="mx-auto flex cursor-pointer items-center gap-2"
                    onClick={generateGoogleCalendarUrl}>
                    <UIcon icon="calendar" />
                    <I18 className="mt-1">Add to Calender</I18>
                  </button>
                </div>
                <button
                  className="mt-5 w-full cursor-pointer rounded-lg border border-border px-2.5 py-1.5"
                  onClick={() => {
                    onSubmit("not-going");
                  }}>
                  Cancel Registration
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="mt-2.5 rounded-xl bg-card p-5"
          text-secondary-foreground>
          <div className="border-b border-border pb-4 font-bold text-secondary-foreground">
            <I18>REGISTER</I18>
          </div>
          <div className="my-5 text-sm font-semibold text-secondary-foreground">
            <I18>Welcome! To join the event, please register below</I18>
          </div>
          <Button
            disabled={rsvpNotAllowed}
            className="w-full cursor-pointer"
            label="Register"
            large
            onClick={() => {
              onSubmit("going");
            }}
          />
        </div>
      )}
    </div>
  );
}

const SidebarEventDetailsCard = connect((s) => ({
  user: s.auth,
  group: s.activeGroup,
  community: s.community,
}))(withToast(SidebarEventDetailsCardComponent));

export default SidebarEventDetailsCard;
