import cx from "classnames";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Community } from "../../types/community/community.type";
import { RealtimeSectionPinnedMessageService } from "../../_service/realtime-section-pinned-message.service";
import { CloseButton } from "../activity/close-button.component";
import I18 from "../atoms/i18";
import { ListView } from "../molecule/listview.component";
import Label from "../typography/label.typography";
import UIcon from "../uicon-component";

/**
 * @params pinnedMessages - The object containing pinned messages.
 */

interface Message {
  id: string;
  // Add other properties of the message object
}
interface PinnedMessageComponentProps {
  community: Community;
  pinnedMessages: Message[];
  activeTabId: string;
  socket: any;
}

export function PinnedMessageComponent({
  community,
  pinnedMessages,
}: PinnedMessageComponentProps) {
  const [showPinnedDetails, setShowPinnedDetails] = useState<boolean>(false);

  const [selectedPinnedMessageId, setSelectedPinnedMessageId] = useState(null);

  // show/hide pinned component
  const handleClick = () => {
    setShowPinnedDetails(!showPinnedDetails);
  };

  const isOnlyItem = pinnedMessages?.length === 1;

  return (
    <>
      <div
        onClick={handleClick}
        className={`IconButton2 flex cursor-pointer place-content-center items-center gap-1 rounded px-1 text-secondary-foreground ${
          showPinnedDetails && "bg-background"
        }`}>
        <UIcon
          icon="thumbtack"
          solid={pinnedMessages?.length > 0}
          className="mt-1"
        />
        {pinnedMessages?.length > 0 && (
          <Label variant="t1">{pinnedMessages?.length}</Label>
        )}
      </div>

      {showPinnedDetails && (
        <div className="absolute -left-2 right-4 top-[12vh] z-50 justify-center md:mx-80">
          <div className="my-2 flex flex-col gap-1 rounded border border-border bg-card">
            <div className="flex items-center justify-between bg-background px-2">
              <Label variant="t1" className="p-3">
                {pinnedMessages?.length} Pinned message
              </Label>
              <CloseButton onClick={handleClick} />
            </div>
            <ListView
              className={cx("max-h-[60vh]", { "overflow-y-auto": !isOnlyItem })}
              noItemsElement={
                <div className="mx-4 flex min-h-[20vh] justify-center bg-card p-4">
                  <Label className="flex items-center justify-center">
                    No pinned message available
                  </Label>
                </div>
              }
              items={pinnedMessages}
              renderItem={(message: any, index: any) => (
                <PinnedMessageDetails
                  community={community}
                  pinnedDetails={message}
                  index={index}
                  totalItems={pinnedMessages?.length}
                  selectedPinnedMessageId={selectedPinnedMessageId}
                  setSelectedPinnedMessageId={setSelectedPinnedMessageId}
                  isOnlyItem={isOnlyItem}
                />
              )}
            />
          </div>
        </div>
      )}
    </>
  );
}

interface CreatedBy {
  id: string;
  picture: string;
  name: string;
}

interface PinnedDetails {
  id: string;
  createdBy: CreatedBy;
  createdAt: string;
  description: string;
  attachments: any;
  length: number;
}

interface PinnedMessageDetailsProps {
  community: Community;
  pinnedDetails: PinnedDetails;
  index: any;
  totalItems: number;
  selectedPinnedMessageId: any;
  setSelectedPinnedMessageId: any;
  isOnlyItem: any;
}
/**
 * @params community - The community object containing community details.
 * @params user - The user object containing user details.
 */
function PinnedMessageDetails({
  community,
  pinnedDetails,
  index,
  totalItems,
  selectedPinnedMessageId,
  setSelectedPinnedMessageId,
  isOnlyItem,
}: PinnedMessageDetailsProps) {
  // unpin the section message (calling unpin-api)
  const unPinSectionMessage = async () => {
    try {
      await RealtimeSectionPinnedMessageService.unpinRealtimeSectionMessage(
        pinnedDetails.id
      );
    } catch (error) {
      console.error("Error fetching pinned message:", error);
    }
  };

  //using attachments to display audio , video & images
  const attachments = pinnedDetails.attachments;
  //getting the last item to remove border bottom
  const isLastItem = index === totalItems - 1;

  return (
    <>
      <div
        className={cx(
          "flex justify-between",
          { "border-b-2": !isLastItem },
          {
            "mx-0 bg-background px-2":
              pinnedDetails.id === selectedPinnedMessageId,
          },
          "mx-2 cursor-pointer break-all border-border"
        )}>
        <div
          className="flex w-full px-3 py-4"
          onClick={(e: any) => {
            e.preventDefault();
            setSelectedPinnedMessageId(pinnedDetails?.id);
          }}>
          <div
            className="mr-3 h-9 w-9 flex-shrink-0 rounded-full bg-cover bg-center bg-no-repeat"
            style={{
              backgroundImage: `url('${pinnedDetails.createdBy.picture}')`,
            }}></div>
          <div className="w-full">
            <div></div>
            <div className="flex gap-2">
              <Label variant="t1" size="caption">
                {pinnedDetails.createdBy.name}
              </Label>
              <Label size="caption">
                {moment(pinnedDetails.createdAt).fromNow()}
              </Label>
            </div>
            <Label size="caption">{pinnedDetails.description}</Label>
            {attachments.map((data: any, index: number) =>
              data.mimeType.startsWith("image") ? (
                <img
                  key={index}
                  src={data.url}
                  alt="pinned"
                  className="mt-2"
                  loading="lazy"
                />
              ) : data.mimeType.startsWith("video") ? (
                <video
                  key={index}
                  className="mt-2 w-full"
                  controls
                  controlsList="nodownload"
                  src={data.url}
                />
              ) : data.mimeType.startsWith("audio") ? (
                <audio
                  key={index}
                  className="mt-2 w-full"
                  controls
                  controlsList="nodownload"
                  src={data.url}
                />
              ) : null
            )}
          </div>
        </div>
        <div className="p-1">
          {community.myRole === "admin" && (
            <PinnedMessageIconMenu
              isLastItem={isLastItem}
              unPinSectionMessage={unPinSectionMessage}
              isOnlyItem={isOnlyItem}
            />
          )}
        </div>
      </div>
    </>
  );
}

interface PinnedMessageIconMenuProps {
  isLastItem: boolean;
  unPinSectionMessage: () => void;
  isOnlyItem: boolean;
}
function PinnedMessageIconMenu({
  isLastItem,
  unPinSectionMessage,
  isOnlyItem,
}: PinnedMessageIconMenuProps) {
  // Reference to the icon menu container
  const iconMenuRef = useRef<HTMLDivElement | null>(null);
  const [isIconMenuOpen, setIsIconMenuOpen] = useState(false);

  useEffect(() => {
    // Function to handle the click outside
    const handleDocumentClick = (event: any) => {
      if (
        iconMenuRef.current &&
        !(iconMenuRef.current! as HTMLElement).contains(event.target as Node)
      ) {
        setIsIconMenuOpen(false);
      }
    };

    // Attach the event listener when the icon menu is open
    if (isIconMenuOpen) {
      document.addEventListener("mousedown", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [isIconMenuOpen]);
  return (
    <div ref={iconMenuRef} className="relative py-1">
      <div
        onClick={() => {
          setIsIconMenuOpen(!isIconMenuOpen);
        }}
        className="flex h-10 w-10 cursor-pointer place-content-center items-center rounded-full hover:shadow">
        <UIcon icon="menu-dots-vertical" solid={true} />
      </div>
      <div>
        {isIconMenuOpen && (
          <div
            className={cx(
              "theme-bg-border absolute right-0 z-10 mt-1 block w-44 rounded border bg-card",
              { "bottom-12": isLastItem && !isOnlyItem }
            )}>
            <div
              className="flex items-center justify-center gap-2 p-3"
              onClick={() => {
                unPinSectionMessage();
                setIsIconMenuOpen(!isIconMenuOpen);
              }}>
              <UIcon icon="thumbtack" />
              <I18>Unpin message</I18>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default PinnedMessageComponent;
