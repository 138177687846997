import { history } from "../_config";
import { mixPanel } from "../_config/mixpanel.config";
const MainUtil = {
  logout: async (community: any, web3Logout: Function) => {
    mixPanel.reset();
    // clear the session
    localStorage.removeItem("pensil.user");
    mixPanel.reset();
    if (web3Logout) {
      await web3Logout();
    }
    // redirect to main app to clear the session there as well
    // const port = window.location.port;
    // redirect to authRedirectUrl of community, if available
    // window.location.reload(); 

    // if (community && community.authRedirectUrl) {
    //     // window.location.href = community.authRedirectUrl;
    //     // dont sent there
    //     window.location.reload();
    // } else {
    //     window.location.reload();
    //           // window.location.href =
    //           //   process.env.REACT_APP_CLIENT_URL +
    //           //   (port ? ":" + port : "") +
    //           //   "?action=logout&redirect=" +
    //           //   window.location.origin; }
  },
  checkIfBlocked: (error: any) => {
    if (
      error?.response?.status === 401 &&
      error.response?.data?.message === "User blocked!"
    ) {
      mixPanel.reset();
      localStorage.removeItem("pensil.user");
      history.push('/login');
      window.location.reload();
      return true;
    }
  }
};

export default MainUtil;
